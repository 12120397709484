<template>
  <div
    :class='loginBackgroundCssClasses'
    :style='loginBackgroundStyle'>
    <div class='w-full lg:overflow-y-scroll lg:h-screen'>
      <signup-form v-if='showSignupForm && !hasValidToken' class='my-16'
        :logo-css-classes='logoCssClass'
        :logo-custom-css='logoCustomCss'
        :logo-height='logoHeight'

        :class='loginBoxCssClasses'
        :style='loginBoxCssStyles'
      >
      <div slot='bottom-content' class='mt-8' v-html='bottomHtmlContent'></div>
      </signup-form>
      <signedup-already v-else-if='showSignupForm && hasValidToken'
        :class='loginBoxCssClasses'
        :style='loginBoxCssStyles' />
      <login-form v-else-if='showLoginForm' class='my-16'
        :single-session='singleSession'
        
        :logo-url='logoUrl'
        :hide-top-logo='hideTopLogo'
        :logo-css-classes='logoCssClass'
        :logo-custom-css='logoCustomCss'
        :logo-height='logoHeight'

        :email-placeholder='idPlaceholderText'
        :email-field-default-value='emailFieldDefaultValue'
        :hide-email-field='hideEmailField'

        :hide-password-field='hidePasswordField'
        :password-field-default-value='passwordFieldDefaultValue'
        :custom-password-field-input-type='passwordFieldType'
        :password-placeholder='passwordPlaceholderText'
        :password-field-name='passwordFieldName'
        
        :hide-field-entry-confirmation='hideFieldEntryConfirmation'
        :custom-email-field-entry-confirmation='emailFieldEntryConfirmation'
        :custom-password-field-entry-confirmation='passwordFieldEntryConfirmation'
        
        :login-button-text='buttonText'
        :button-css-class-override='buttonCssClassOverride'
        :button-style-override='buttonStyleOverride'
        :include-signup-link='includeSignupLink'

        :class='loginBoxCssClasses'
        :style='loginBoxCssStyles'>
        <div v-if='topHtmlContent' v-html='topHtmlContent'></div>
        <div slot='bottom-content' class='mt-8' v-html='bottomHtmlContent'></div>
      </login-form>
      <button v-if='showToggleFormType'
        @click='toggleFormType'
        class='block text-center text-sm border border-transparent border-gray-200 shadow-md solid rounded-md px-12 py-3 mx-auto mb-16'>
        {{signupLoginToggleText}}
      </button>
    </div>
    <div v-if='otherLoginPageContent' 
      v-html='otherLoginPageContent' 
      :class='otherLoginContentClasses'
      :style='otherLoginContentCssStyles'></div>
  </div>

</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import SignupForm from      '@/components/jeilpharm/SignupForm.vue'
import SignedupAlready from '@/components/jeilpharm/SignedupAlready.vue'
import LoginForm  from      '@/components/LoginForm.vue'

export default {
  name: 'SignupOrLogin',
  props: ['showToggleFormType', 'initialFormType'],
  components: {
    SignupForm,
    SignedupAlready,
    LoginForm,
  },
  data () {
    return {
      formType: this.initialFormType,
    }
  },
  computed: {
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapGetters('events', [
      'showingEventLoginPageConfigurations',
    ]),
    singleSession () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.singleSession : false
    },
    logoUrl () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.logoUrl : ''
    },
    logoHeight () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.logoHeight : ''
    },
    logoCssClass () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.logoCssClass : ''
    },
    logoCustomCss () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.logoCustomCss : ''
    },
    hideTopLogo () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.hideTopLogo : false
    },
    hideEmailField () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.hideEmailField : false
    },
    idPlaceholderText () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.emailPlaceholderText : ''
    },
    emailFieldDefaultValue () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.emailFieldDefaultValue : ''
    },
    hidePasswordField () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.hidePasswordField : false
    },
    passwordFieldName () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.passwordFieldName : 'password'
    },
    passwordFieldDefaultValue () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.passwordFieldDefaultValue : ''
    },
    passwordPlaceholderText () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.passwordPlaceholderText : ''
    },
    passwordFieldType () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.customPasswordFieldInputType : 'password'
    },
    hideFieldEntryConfirmation () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.hideFieldEntryConfirmation : false
    },
    emailFieldEntryConfirmation () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.customEmailFieldEntryConfirmation : ''
    },
    passwordFieldEntryConfirmation () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.customPasswordFieldEntryConfirmation : ''
    },
    buttonText () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.loginButtonText : ''
    },
    buttonCssClassOverride () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.buttonCssClassOverride : ''
    },
    buttonStyleOverride () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.buttonStyleOverride : ''
    },
    includeSignupLink () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.includeSignupLink : false
    },
    topHtmlContent () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.topHtmlContent : ''
    },
    bottomHtmlContent () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.bottomHtmlContent : ''
    },
    loginBoxAlignment () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.loginBoxAlignment : 'center'
    },
    loginBoxCssClasses () {
      let mobileClasses = 'w-11/12 flex flex-col items-center justify-center py-16 px-8'
      let fullscreenClasses = 'lg:w-1/2 lg:max-w-2xl lg:flex-shrink-0 lg:flex-grow-0 lg:py-16 lg:px-16 lg:border-0 lg:rounded-xl lg:opacity-95'
      let fullscressnHoverClasses = 'hover:opacity-100'
      let overrideClasses = (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.loginBoxCssClasses : ''
      return `standard-transition ${mobileClasses} ${fullscreenClasses} ${fullscressnHoverClasses} ${overrideClasses}`
    },
    loginBoxCssStyles () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.loginBoxCssStyles : ''
    },
    loginBackgroundImage () {
      return (this.showingEventLoginPageConfigurations && this.showingEventLoginPageConfigurations.backgroundImageUrl) ? `background-image: url('${this.showingEventLoginPageConfigurations.backgroundImageUrl}');` : ''
    },
    loginBackgroundCssClasses () {
      const baseClasses     = 'flex min-h-screen relative bg-top-left bg-no-repeat bg-cover'
      const mobileClasses   = 'flex flex-col justify-center items-center'
      let boxAlginmentClasses = ''
      if (this.loginBoxAlignment === 'center') {
        boxAlginmentClasses = 'lg:flex-row lg:justify-center'
      } else if (this.loginBoxAlignment === 'left') {
        boxAlginmentClasses = 'lg:flex-row lg:justify-start'
      } else if (this.loginBoxAlignment === 'right') {
        boxAlginmentClasses = 'lg:flex-row-reverse lg:justify-end'
      }
      const desktopClasses  = `${boxAlginmentClasses} lg:items-center`
      const overrideClasses = (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.loginBackgroundClasses : ''
      return `${baseClasses} ${mobileClasses} ${desktopClasses} ${overrideClasses}`
    },
    loginBackgroundStyle () {
      let configStyles = (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.loginBackgroundStyle : ''
      return `${this.loginBackgroundImage} ${configStyles}`
    },
    otherLoginPageContent () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.otherLoginPageContent : ''
    },
    otherLoginContentClasses () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.otherLoginContentClasses : ''
    },
    otherLoginContentCssStyles () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.otherLoginContentCssStyles : ''
    },
    showSignupForm () {
      return this.formType === 'signup'
    },
    showLoginForm () {
      return this.formType === 'login'
    },
    signupLoginToggleText () {
      return this.showSignupForm ? '로그인하러 가기' : '등록하러 가기'
    },
    hasSignedUp () {
      return this.hasValidToken
    },
  },
  methods: {
    ...mapActions('users', [
      'checkTokenStatus',
      'getProfile',
    ]),
    redirectAfterAction () {
      let redirectRoute = this.$route.query.redirect_route ? this.$route.query.redirect_route : 'Home'
      this.$router.push({name: redirectRoute})
    },
    toggleFormType () {
      if (this.showSignupForm) {
        this.formType = 'login'
      } else {
        this.formType = 'signup'
      }
    },
  },
  created () {
    this.checkTokenStatus().then(() => {
      if (this.hasValidToken) {
        this.getProfile()
        // do this once they can login. for now, just show them the registered message
      //   this.redirectAfterAction()
      }
      // stay here for now
    }).catch(error => {
      console.log(error)
    })
  },
  mounted () {
    this.$emit('update:layout', 'empty-page')
  },
  beforeDestroy () {
    this.$emit('update:layout', 'default-layout')
  },
}
</script>
