<template>
  <div>
    <img 
      :src='logoUrlTop'
      :class='logoCssClass'
      :style='logoStyle'>
    <h1 class='w-full text-left my-6 text-xl text-gray-900 font-semibold'>등록하기</h1>
    <div class='mb-6 w-full'>
      <label class='uppercase text-sm text-gray-500 mb-2'>성함</label>
      <input
        v-model='profile.name'
        class='border py-2 px-2 rounded block w-full'
        type='text'
        placeholder='성함'>
    </div>
    <div class='mb-6 w-full'>
      <label class='uppercase text-sm text-gray-500 mb-2'>병원명</label>
      <input
        v-model='profile.hospitalName'
        class='border py-2 px-2 rounded block w-full'
        type='text'
        placeholder='병원명'>
    </div>
    <div class='mb-6 w-full'>
      <label class='uppercase text-sm text-gray-500 mb-2'>진료과</label>
      <input
        v-model='profile.department'
        class='border py-2 px-2 rounded block w-full'
        type='text'
        placeholder='진료과'>
    </div>
    <div class='mb-6 w-full'>
      <label class='uppercase text-sm text-gray-500 mb-2'>핸드폰번호</label>
      <input
        v-model='profile.phoneNumber'
        class='border py-2 px-2 rounded block w-full'
        type='text'
        placeholder='핸드폰번호'>
    </div>
    <div class='mb-6 w-full'>
      <label class='uppercase text-sm text-gray-500 mb-2'>E-MAIL 주소</label>
      <input
        v-model='signupParams.email'
        class='border py-2 px-2 rounded block w-full'
        type='text'
        placeholder='E-MAIL 주소'>
    </div>
    <div class='my-6'>
    <div v-for='(term, index) in terms'
        :key='`term-${index}`'
        class='text-xs border rounded-md my-2 w-full'>
        <div class='bg-gray-100 p-4' v-html='term.text'></div>
        <label class='border-t p-4 flex flex-row justify-start items-center gap-x-0.5 text-normal'>
          <input type='checkbox' :name='`checkbox-${index}`' v-model='term.agreed'>
          <span class='px-1'>{{ term.confirmationText }}</span>
        </label>
      </div>
    </div>
    <button
      :disabled='disabledSignupButton'
      @click='submitSignup'
      :class='buttonCssClasses'
      :style='buttonStyle'>
      등록하기
    </button>
    <slot name='bottom-content'></slot>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { mapFields }                        from 'vuex-map-fields'
import SignupTermsHelpers from '@/utils/signup-terms-helpers.js'

export default {
  name: 'SignupForm',
  watch: {
    'showingEventId': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal && this.terms.length === 0) {
          // signup 할 때 terms 없으면, like koa sso login or import data from excel
          this.terms = SignupTermsHelpers.signupTerms(newVal)
        }
      },
      immediate: true 
    },
  },
  computed: {
    ...mapFields('users', [
      'signupParams',
      'profile.extraInfo',
      'profile',
      'terms',
    ]),
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapGetters('events', [
      'eventMainThemeColor',
      'eventConfigLogoImageUrl',
      'showingEventLoginPageConfigurations',
      'showingEventId',
    ]),
    buttonStyle () {
      return (this.buttonStyleOverride) ? this.buttonStyleOverride : `background-color: ${this.eventMainThemeColor};`
    },
    buttonOverrideCss () {
      return (this.buttonCssClassOverride) ? this.buttonCssClassOverride : 'w-full p-4 rounded-md text-white'
    },
    buttonCssClasses () {
      return `${this.emptyFieldSubmitButtonClass} ${this.buttonOverrideCss}`
    },
    emptyFieldSubmitButtonClass () {
      return (this.disabledSignupButton) ? 'opacity-25 cursor-not-allowed' : ''
    },
    logoUrlTop () {
      return (this.logoUrl) ? this.logoUrl : this.eventConfigLogoImageUrl
    },
    logoHeightStyle () {
      return (this.logoHeight) ? `height: ${this.logoHeight};` : 'height: 3rem;'
    },
    logoCustomStyle () {
      return (this.logoCustomCss) ? `${this.logoCustomCss}` : ''
    },
    logoStyle () {
      return `${this.logoHeightStyle} ${this.logoCustomStyle}`
    },
    logoUrl () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.logoUrl : ''
    },
    logoHeight () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.logoHeight : ''
    },
    logoCssClass () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.logoCssClass : ''
    },
    logoCustomCss () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.logoCustomCss : ''
    },
    passwordFieldDefaultValue () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.passwordFieldDefaultValue : ''
    },
    emptyEmailField () {
      return !this.signupParams.email
    },
    emptyName () {
      return !this.profile.name
    },
    emptyHospitalName () {
      return !this.profile.hospitalName
    },
    emptyDepartment () {
      return !this.profile.department
    },
    emptyMobile () {
      return !this.profile.phoneNumber || this.profile.phoneNumber.length < 6
    },
    notAgreedTerms () {
      return !this.terms.filter(term => term.required).every(term => term.agreed)
    },
    validEmail () {
      let mailformat = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g
      return mailformat.test(this.signupParams.email)
    },
    disabledSignupButton () {
      return this.emptyEmailField || !this.validEmail ||
             this.emptyName || 
             this.emptyMobile ||
             this.emptyDepartment ||
             this.emptyHospitalName ||
             (this.terms.length > 0 && this.notAgreedTerms) // if there are terms, and they're not agreed, disable the button
            //  !this.extraInfo.disclaimer ||
            //  !this.extraInfo.iAmADoctor 
    },
    disabledSignupButtonClass () {
      return this.disabledSignupButton ? 'opacity-50' : ''
    },
    redirectRouteObject () {
      const redirectRoute = this.$route.query.redirect_route ? this.$route.query.redirect_route : 'Home'
      const redirectRouteSponsorId = this.$route.query.sponsor_id ? this.$route.query.sponsor_id : ''

      let routeObject = { name: redirectRoute }

      if (redirectRoute === 'Sponsor' && redirectRouteSponsorId) {
        routeObject['query'] = {sponsor_id: redirectRouteSponsorId }
      }
      return routeObject
    },
  },
  methods: {
    ...mapActions('users', [
      'signup',
      'createProfile',
      'patchTerms',
    ]),
    ...mapActions('users', [
      'checkTokenStatus',
    ]),
    formatProfilePhoneNumber (data) {
      this.phoneNumber = data.formatInternational
      this.$emit('update-phone-number-is-valid', data.isValid)
    },
    submitSignup () {
      if (this.passwordFieldDefaultValue) {
        this.signupParams.password = this.passwordFieldDefaultValue
        this.signupParams.confirmPassword = this.passwordFieldDefaultValue
      } else {
        this.signupParams.password = this.profile.name
        this.signupParams.confirmPassword = this.profile.name
      }
      this.signup().then(() => {
        if (this.terms.length > 0) {
          this.patchTerms()
        }
        this.createProfile().then(() => {
          this.$router.push(this.redirectRouteObject)
        })
      }).catch((error) => {
        if (error.response.status === 409) {
          this.$alert('이미 등록된 이메일입니다. 이메일을 확인 후 다시 등록해주세요.', {
            confirmButtonText: '확인',
            type: 'warning'
          })   
        } else {
          console.error(error)
        }
      })
    },
  },
}
</script>
