<template>
  <div class='w-full max-w-4xl bg-white px-8 py-16 rounded-md my-16'>
    <img 
      :src='logoUrlTop'
      :class='logoCssClass'
      style='display: block; margin: 0 auto; margin-bottom: 2rem;'
      :style='logoStyle'>
    <h1 class='text-3xl font-semibold text-gray-900 text-center'>사전등록이 완료되었습니다.</h1>
    <div class='p-8 bg-gray-50 rounded my-8 w-full'>
      <div class='mb-4 w-full flex flex-row items-start gap-x-4'>
        <label class='uppercase text-gray-500 mb-2 w-24'>성함</label>
        <div class=''>{{profile.name}}</div>
      </div>
      <div class='mb-4 w-full flex flex-row items-start gap-x-4'>
        <label class='uppercase text-gray-500 mb-2 w-24'>병원명</label>
        <div class=''>{{profile.hospitalName}}</div>
      </div>
      <div class='mb-4 w-full flex flex-row items-start gap-x-4'>
        <label class='uppercase text-gray-500 mb-2 w-24'>진료과</label>
        <div class=''>{{profile.department}}</div>
      </div>
      <div class='mb-4 w-full flex flex-row items-start gap-x-4'>
        <label class='uppercase text-gray-500 mb-2 w-24'>핸드폰번호</label>
        <div class=''>{{profile.phoneNumber}}</div>
      </div>
      <div class='w-full flex flex-row items-start gap-x-4'>
        <label class='uppercase text-gray-500 mb-2 w-24'>E-MAIL 주소</label>
        <div class=''>{{emailAddressString}}</div>
      </div>
    </div>
    <button
      @click='logoutApp'
      :class='buttonCssClasses'
      :style='buttonStyle'>
      다른 계정으로 등록하기
    </button>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'SignedupAlready',
  computed: {
    ...mapState('users', [
      'profile',
      'signupParams',
    ]),
    ...mapGetters('events', [
      'eventMainThemeColor',
      'eventConfigLogoImageUrl',
      'showingEventLoginPageConfigurations',
    ]),
    buttonStyle () {
      return (this.buttonStyleOverride) ? this.buttonStyleOverride : `background-color: ${this.eventMainThemeColor};`
    },
    buttonOverrideCss () {
      return (this.buttonCssClassOverride) ? this.buttonCssClassOverride : 'w-full p-4 rounded-md text-white'
    },
    buttonCssClasses () {
      return `${this.buttonOverrideCss}`
    },
    emailAddressString () {
      return (this.signupParams.email) ? this.signupParams.email : this.profile.email
    },
    logoUrlTop () {
      return (this.logoUrl) ? this.logoUrl : this.eventConfigLogoImageUrl
    },
    logoHeightStyle () {
      return (this.logoHeight) ? `height: ${this.logoHeight};` : 'height: 5rem;'
    },
    logoCustomStyle () {
      return (this.logoCustomCss) ? `${this.logoCustomCss}` : ''
    },
    logoStyle () {
      return `${this.logoHeightStyle} ${this.logoCustomStyle}`
    },
    logoUrl () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.logoUrl : ''
    },
    logoHeight () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.logoHeight : ''
    },
    logoCssClass () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.logoCssClass : ''
    },
    logoCustomCss () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.logoCustomCss : ''
    },
  },
  methods: {
    ...mapActions('users', [
      'logout',
    ]),
    ...mapMutations('users', [
      'resetUserData',
    ]),
    logoutApp () {
      this.logout().then(() => {
        this.resetUserData()
      })
    },
  },
}
</script>
